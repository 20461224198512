export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const INSERT_APP_DATA_START = 'INSERT_APP_DATA_START';
export const INSERT_APP_DATA_SUCCESS = 'INSERT_APP_DATA_SUCCESS';
export const INSERT_APP_DATA_ERROR = 'INSERT_APP_DATA_ERROR';

export const GET_APP_DATA_START = 'GET_APP_DATA_START';
export const GET_APP_DATA_SUCCESS = 'GET_APP_DATA_SUCCESS';
export const GET_APP_DATA_ERROR = 'GET_APP_DATA_ERROR';

export const INSERT_CLY_LINK_START = 'INSERT_CLY_LINK_START';
export const INSERT_CLY_LINK_SUCCESS = 'INSERT_CLY_LINK_SUCCESS';
export const INSERT_CLY_LINK_ERROR = 'INSERT_CLY_LINK_ERROR';

export const GET_CLY_START = 'GET_CLY_START';
export const GET_CLY_SUCCESS = 'GET_CLY_SUCCESS';
export const GET_CLY_ERROR = 'GET_CLY_ERROR';

export const REMOVE_USER_START = 'REMOVE_USER_START';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR';