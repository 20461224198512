import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDgUCRiInhDRpsthV6qNjSUlrtAnZRB1PQ",
  authDomain: "immigramio.firebaseapp.com",
  databaseURL: "https://immigramio.firebaseio.com",
  projectId: "immigramio",
  storageBucket: "immigramio.appspot.com",
  messagingSenderId: "118325591771",
  appId: "1:118325591771:web:0b0eef1072ef147239d6fe",
  measurementId: "G-2S345FWJRH"
};

firebase.initializeApp(firebaseConfig);

export const fauth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();