import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import './App.css';
import Auxy from './hoc/Auxy';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';

// import app elements
import Landing from '../src/components/landing/Landing';
import Application from '../src/components/application/Application';
import Login from '../src/components/login/Login';
import Admin from './components/admin/Admin';
import Promo from './components/promo/Promo';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { windowWidth: 0, windowHeight: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.props.autoSignIn()
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {

    let routes = <Switch>
      
    </Switch>

    if ( this.props.isAuthenticated ) {
      routes = (
        <Switch>
          <Route path='/' exact component={Landing} />
          <Route path='/app' exact component={Application} />
          <Route path='/admin' exact component={Admin} />
          <Route path='/promo' exact component={Promo} />
          <Redirect to='/admin' />
        </Switch>
      );
    } else {
      routes = (
        <Switch>
          <Route path='/' exact component={Landing} />
          <Route path='/app' exact component={Application} />
          <Route path='/login' exact component={Login} />
          <Route path='/admin' exact component={Admin} />
          <Route path='/promo' exact component={Promo} />
          <Redirect to='/' />
        </Switch>
      )
    }

    return (
      <Auxy>
        <div style={{display: this.props.loading === false ? 'block' : 'none'}}>
          {routes}
        </div>
      </Auxy>
    );

  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    autoSignIn: () => dispatch(actions.authCheckState())
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );