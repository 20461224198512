import * as actionTypes from './actionTypes';
import { fauth, db } from '../../_config/firebase';
import firebase from 'firebase';

export const insertDataStart = () => {
    return {
        type: actionTypes.INSERT_APP_DATA_START
    };
};

export const insertDataSuccess = () => {
    return {
        type: actionTypes.INSERT_APP_DATA_SUCCESS,
    };
};

export const insertDataError = () => {
    return {
        type: actionTypes.INSERT_APP_DATA_ERROR,
    };
};

export const getDataStart = () => {
    return {
        type: actionTypes.GET_APP_DATA_START
    };
};

export const getDataSuccess = (data) => {
    return {
        type: actionTypes.GET_APP_DATA_SUCCESS,
        data: data
    };
};

export const getDataError = () => {
    return {
        type: actionTypes.GET_APP_DATA_ERROR,
    };
};

export const insertData = (email, fields, progress) => {
    return dispatch => {
        dispatch(insertDataStart());
        let data = {};
        fields.forEach(field => {
            data[field.label] = field.value
        })
        if (fields.length === 1 && fields[0].label === 'email') {
            data['timestamp'] = firebase.firestore.Timestamp.now();
        }
        data['progress'] = progress;
        db.collection('users').doc(email).set(data, {merge: true})
        .then(function(docRef) {
            dispatch(insertDataSuccess());
        })
        .catch(function(error) {
            dispatch(insertDataError(error.message));
        })
    };
};

export const getData = () => {
    return dispatch => {
        dispatch(getDataStart());
        db.collection('users').get().then(function(querySnapshot) {
            let clientsData = []
            querySnapshot.forEach(function(doc) {
                let newData = doc.data();
                newData.timestamp = doc.data().timestamp ? doc.data().timestamp.toDate().toString() : new Date().toString();
                clientsData.push(newData)
            });
            dispatch(getDataSuccess(clientsData));
        })
    }
}