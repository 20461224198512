import * as actionTypes from './actionTypes';
import { fauth, db } from '../../_config/firebase';
import firebase from 'firebase';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user
    };
};

export const authError = (err) => {
    return {
        type: actionTypes.AUTH_ERROR,
        error: err
    };
};

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        fauth.signInWithEmailAndPassword(email, password)
        .then((response) => {
            dispatch(authSuccess(response.user));
        })
        .catch((err) => {
            dispatch(authError(err.message));
        })
    };
};

export const authCheckState = () => {
    return dispatch => {
        dispatch(authStart());
        fauth.onAuthStateChanged(function(user) {
            if (user) {
                dispatch(authSuccess(user))
            } else {
                dispatch(authError('Not authorized'))
            }
        })
    }
}

export const logout = () => {
    return dispatch => {
        fauth.signOut().then(function() {
            dispatch(authLogout())
        }).catch(function() {
            console.log('error while signing out')
        })
    }
}