import * as actionTypes from './actionTypes';
import { fauth, db } from '../../_config/firebase';
import firebase from 'firebase';
import moment from 'moment'

export const removeUserStart = () => {
    return {
        type: actionTypes.REMOVE_USER_START
    };
};

export const removeUserSuccess = () => {
    return {
        type: actionTypes.REMOVE_USER_SUCCESS,
    };
};

export const removeUserError = () => {
    return {
        type: actionTypes.REMOVE_USER_ERROR,
    };
};

export const removeUser = (email) => {
    return dispatch => {
        dispatch(removeUserStart());
        db.collection('users').doc(email).delete()
        .then(function() {
            dispatch(removeUserSuccess());
        })
        .catch(function(error) {
            dispatch(removeUserError(error.message));
        })
    };
};