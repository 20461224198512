import React, { Component } from 'react';
import './Admin.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Auxy from '../../hoc/Auxy';
import moment from 'moment';
import {Area, AreaChart, linearGradient, ResponsiveContainer } from 'recharts';

//import UI/UX elements 

import Input from '../../UI/input/Input';
import Button from '../../UI/button/Button';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreDataShown: false,
            clientInfo: {},
            calendlyLink: {
                placeholder: 'insert calendly link',
                value: '',
                label: 'calendly link'
            },
            clientsList: {
                order: 'ascending',
                list: [{email: 'a@a.com', progress: 0.2, timestamp: 'Fri Aug 14 2020 06:56:16 GMT-0400 (Eastern Daylight Time)'}],
                statsPerDay: []
            },
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    };

    componentDidMount() {
        this.props.getData();
        this.updateWindowDimensions();
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.updateWindowDimensions);
        if (!this.props.isAuthenticated) {
            this.props.history.push({pathname: '/login'})
        }
        this.sortClients();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data !== prevProps.data) {
            let newClientsList = {...this.state.clientsList};
            newClientsList.list = this.props.data.sort(function(a,b) {return moment(b.timestamp) - moment(a.timestamp)});
            let statsPerDay = {}
            this.props.data.reduce(function (result, account) {
                var day = moment(account.timestamp).format('YYYY-MM-DD');
                if (!result[day]) {
                    result[day] = 0;
                }
                statsPerDay[day]++;
                return statsPerDay;
            });
            newClientsList.statsPerDay = Object.keys(statsPerDay).map(key =>({date: key, count: statsPerDay[key]})).reverse();
            this.setState({clientsList: newClientsList})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    
    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({moreDataShown: false})
        }
    }

    showClientsData(email) {
        let newClientInfo = this.state.clientsList.list.filter(client => client.email === email)[0];
        this.setState({moreDataShown: true, clientInfo: newClientInfo})
    }

    removeSelectedClient() {
        let newClientInfo = null
        this.setState({moreDataShown: false, clientInfo: newClientInfo})
    }

    inputChangedHandler (e) {
        let newCalendlyLink = {...this.state.calendlyLink};
        newCalendlyLink.value = e.target.value;
        this.setState({calendlyLink: newCalendlyLink})
    }

    buttonClickedHandler () {
        this.props.insertCly(this.state.calendlyLink.value);
        this.props.getCly()
    }

    removeUserClicked () {
        this.props.removeUser(this.state.clientInfo.email);
        this.props.getData();
        this.setState({moreDataShown: false});
    }

    sortClients () {
        let newClientsList = {...this.state.clientsList};
        if (this.state.clientsList.order === 'ascending') {
            newClientsList.list = this.state.clientsList.list.sort(function(a,b) {return moment(b.timestamp) - moment(a.timestamp)});
            newClientsList.order = 'descending';
            this.setState({clientsList: newClientsList})
        } else {
            newClientsList.list = this.state.clientsList.list.sort(function(a,b) {return moment(a.timestamp) - moment(b.timestamp)});
            newClientsList.order = 'ascending';
            this.setState({clientsList: newClientsList})
        }
    }

    render () {

        let clients = <div></div>
        if (this.state.clientsList.list !== []) {
            clients = <tbody>
                {this.state.clientsList.list.map(user => {
                return <tr key={user.email} className='client' onClick={() => this.showClientsData(user.email)}>
                    <td style={{padding: '4px'}}>{user.email}</td>
                    <td style={{padding: '4px'}}>{moment(user.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</td>
                    <td style={{padding: '4px'}}>
                        <div style={{backgroundColor: '#0052CC', width: user.progress * 0.94 * 100 + '%', padding: '2%', color: 'white', borderRadius: '8px'}}>{(user.progress * 100).toFixed(2) + '%'}</div>
                    </td>
                </tr>
                })}
            </tbody>
        }

        let listOfClients = <div style={{margin: '24px 0'}}>
            <table style={{width: '90%'}}>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th style={{cursor: 'pointer'}} onClick={() => this.sortClients()}>Created</th>
                        <th>Progress</th>
                    </tr>
                </thead>
                {clients}
            </table>
        </div>

        let clientInfoElement = <div>
            <div style={{height: this.state.windowHeight - 183, marginTop: '12px', overflow: 'scroll', paddingBottom: '48px'}}>
                <h2 style={{marginTop: 0}}>Client Application</h2>
                <p className='label'>Given Name</p>
                <p>{this.state.clientInfo['Given Name']}</p>
                <p className='label'>Family Name</p>
                <p>{this.state.clientInfo['Family Name']}</p>
                <p className='label'>Country of Residence</p>
                <p>{this.state.clientInfo['Country of residence']}</p>
                <p className='label'>Email</p>
                <p>{this.state.clientInfo['email']}</p>
                <p className='label'>Phone</p>
                <p>{this.state.clientInfo['Phone']}</p>
                <p className='label'>Created At</p>
                <p>{this.state.clientInfo['timestamp']}</p>
                <p className='label'>Age</p>
                <p>{this.state.clientInfo['Age']}</p>
                <p className='label'>What is your intention in the UK?</p>
                <p>{this.state.clientInfo['What is your intention?']}</p>
                <p className='label'>Why do you want to relocate?</p>
                <p>{this.state.clientInfo['Why do you want to relocate?']}</p>
                <p className='label'>Your current occupation</p>
                <p>{this.state.clientInfo['Your current occupation']}</p>
                <p className='label'>Your current position and company name</p>
                <p>{this.state.clientInfo['Your current position and company name']}</p>
                <p className='label'>Company size: people, investments, market presense</p>
                <p>{this.state.clientInfo['Company size: people, investments, market presense']}</p>
                <p className='label'>Do you manage people?</p>
                <p>{this.state.clientInfo['Do you manage people?']}</p>
                <p className='label'>Do you work with tech: if yes, please elaborate</p>
                <p>{this.state.clientInfo['Do you work with tech: if yes, please elaborate']}</p>
                <p className='label'>Please share your greatest achievements (can be product release, key hirings, opened a side company with a director, virtually anything)</p>
                <p>{this.state.clientInfo['Please share your greatest achievements (can be product release, key hirings, opened a side company with a director, virtually anything)']}</p>
                <p className='label'>How many years of relevant experience do you have?</p>
                <p>{this.state.clientInfo['How many years of relevant experience do you have?']}</p>
                <p className='label'>Please share details on previous experience</p>
                <p>{this.state.clientInfo['Please share details on previous experience']}</p>
                <p className='label'>What your bisiness is about</p>
                <p>{this.state.clientInfo['What your bisiness is about']}</p>
                <p className='label'>Have you received investments? If yes, what is the current stage and total funding?</p>
                <p>{this.state.clientInfo['Have you received investments? If yes, what is the current stage and total funding?']}</p>
                <p className='label'>Do you have any traction: share any information that you can share, i.e. number of users, turnover etc.?</p>
                <p>{this.state.clientInfo['Do you have any traction: share any information that you can share, i.e. number of users, turnover etc.?']}</p>
                <p className='label'>Please share your greatest achievements (can be product release, key hirings, developed technology, virtually anything)</p>
                <p>{this.state.clientInfo['Please share your greatest achievements (can be product release, key hirings, developed technology, virtually anything)']}</p>
                <p className='label'>What is your relocation budget?</p>
                <p>{this.state.clientInfo['What is your relocation budget?']}</p>
                <p className='label'>Anything you want to ask us?</p>
                <p>{this.state.clientInfo['Anything you want to ask us?']}</p>
            </div>
        </div>

        let removeButton = <div></div>
        if (this.state.moreDataShown) {
            removeButton = <div style={{position: 'absolute', width: '100%', bottom : '12px'}}>
                <Button text='Remove' clicked={() => this.removeUserClicked()}></Button>
            </div>
        }

        let overallStatistics = <div style={{display: 'flex', width: '156px', padding: '12px 24px', margin: '12px 24px', boxShadow: '0 0 20px 1px #d4d4d4', borderRadius: '20px'}}>
            <div style={{}}>
                <p style={{fontSize: '40px', fontWeight: '500', margin: 0, marginTop: '4px'}}>{this.props.data.length.toFixed(0)}</p>
                <p style={{color: 'grey', fontSize: '12px', textTransform: 'uppercase', margin: 0}}>Leads</p>
            </div>
            
            <div style={{height: '75px', width: '100px', marginLeft: '8px'}}>
                <ResponsiveContainer width='100%' height='100%' >
                    <AreaChart data={this.state.clientsList.statsPerDay}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="count" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>

        let todayStats = <div></div>

        if (this.state.clientsList.statsPerDay.filter(item => item.date === moment(new Date()).format('YYYY-MM-DD')).length > 0) {
            todayStats = 
                <div style={{width: 'auto', padding: '12px 24px', margin: '12px 24px', boxShadow: '0 0 20px 1px #d4d4d4', borderRadius: '20px'}}>
                    <p>{this.state.clientsList.statsPerDay.filter(item => item.date === moment(new Date()).format('YYYY-MM-DD'))[0].count}</p>
                    <p style={{color: 'grey', fontSize: '12px', textTransform: 'uppercase', margin: 0}}>Leads Today</p>
                </div>
        } else {
            todayStats = <div style={{width: 'auto', padding: '12px 24px', margin: '12px 24px', boxShadow: '0 0 20px 1px #d4d4d4', borderRadius: '20px'}}>
                <p style={{fontSize: '40px', fontWeight: '500', margin: 0, marginTop: '4px'}}>0</p>
                <p style={{color: 'grey', fontSize: '12px', textTransform: 'uppercase', margin: 0}}>Leads Today</p>
            </div>
        }

        let clientCard = this.state.clientsList.list.map(account => {
            return <div style={{borderRadius: '8px', boxShadow: '0 0 10px 0 lightgrey', width: '100%', overflow:'hidden', marginBottom: '12px'}} onClick={() => this.showClientsData(account.email)} className='clientCard'>
                <div key={account.email} style={{display: 'flex', justifyContent: 'space-around'}}>
                    <div style={{lineHeight: '80px', fontSize: '24px', flex: 3, paddingLeft: '24px'}}>{account.email}</div>
                    <div style={{flex: 1}}>
                        <div style={{fontSize: '16px', lineHeight: '32px', fontWeight: 500, marginTop: '8px'}}>{account.progress * 100 + '%'}</div>
                        <div style={{fontSize: '16px', lineHeight: '32px', marginBottom: '8px', color: 'grey', fontWeight: 300}}>{moment(account.timestamp).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
                <div style={{height: '4px', width: (account.progress * 100 + '%'), backgroundColor: 'blue'}}></div>
            </div>
        })

        let rightPanel = <div></div>

        if (this.state.moreDataShown && this.state.windowWidth >= 720) {
            rightPanel = <div style={{marginLeft: '24px'}}>
                {clientInfoElement}
                {removeButton}
            </div>
        }

        let modalWindow = <div></div>
        
        if (this.state.moreDataShown && this.state.windowWidth < 720) {
            console.log('?')
            modalWindow = <div ref={this.setWrapperRef} style={{width: this.state.windowWidth - 48, position: 'absolute', padding: '24px', backgroundColor: 'white', top: '72px', boxShadow: '0px -96px 1px rgb(193 193 193 / 0.85)', borderRadius: '16px'}}>
                <div style={{position: 'absolute', right: '24px', cursor: 'pointer', textTransform: 'uppercase', color: 'grey', fontSize: '12px'}} onClick={() => this.setState({moreDataShown: false})}>Close</div>
                {clientInfoElement}
                {removeButton}
            </div>
        }

        return (
            <Auxy>
                <div style={{}} >
                    <div style={{display: 'flex'}}>
                        {todayStats}
                        {overallStatistics}
                    </div>
                    <div style={{display: 'flex'}}> 
                        <div style={{margin: '12px', width: this.state.windowWidth < 720 ? '94%' : '100%', flex: 1, height: this.state.windowHeight - 172, overflow: 'scroll', padding: '12px'}}>
                            {clientCard}
                        </div>
                        <div style={{flex: this.state.windowWidth < 720 ? 0 : 1, height: '100%', overflow: 'hidden', display: this.windowWidth < 720 ? 'none' : 'block'}}>
                            {rightPanel}
                        </div>
                    </div>
                    {modalWindow}
                </div>
            </Auxy>
        );
    };
}
    
const mapStateToProps = state => {
    return {
        data: state.application.data,
        link: state.cly.link,
        isAuthenticated: state.auth.isAuthenticated,
    };
};
    
const mapDispatchToProps = dispatch => {
    return {
        insertCly: (link) => dispatch(actions.insertCly(link)),
        getCly: () => dispatch(actions.getCly()),
        getData: () => dispatch(actions.getData()),
        removeUser: (email) => dispatch(actions.removeUser(email))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Admin );