import React, { Component } from 'react';
import './Button.css';
import Auxy from '../../hoc/Auxy'

//import UI/UX elements 

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
          };
    };

    render () {
        let btnElement = <div></div>
        console.log(this.props.isLoading, this.props.type)
        switch (this.props.type) {
            case 'login':
                btnElement = 
                <button
                    className={this.props.isLoading ? 'main-action disabled' : 'main-action'}
                    onClick={this.props.clicked}
                >
                    {this.props.text}
                </button>
                break;
            case 'landing':
                btnElement = 
                <button
                    className={this.props.isLoading ? 'landing-main-action disabled' : 'landing-main-action'}
                    style={{}}
                    onClick={this.props.clicked}
                >
                    {this.props.text}
                </button>
                break;
            default: btnElement = 
                <button
                    className={this.props.isLoading ? 'main-action disabled' : 'main-action'}
                    onClick={this.props.clicked}
                >
                    {this.props.text}
                </button>
        }
        return (
            <Auxy>
                {btnElement}
            </Auxy>
        );
    };
}

export default Button;