import React, { Component } from 'react';
import './Landing.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Auxy from '../../hoc/Auxy'

//import UI/UX elements 

import Input from '../../UI/input/Input';
import Button from '../../UI/button/Button';
import { authLogout } from '../../store/actions/auth';
import logo from '../../_assets/immigram_logo_bw.png'

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            button: {
                isLoading: false,
                status: 'notClicked'
            },
            emptyEmail: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            language: 'eng'
        };
        this.windowResizeHandler = this.windowResizeHandler.bind(this)
    };

    componentDidMount () {
        window.addEventListener("resize", this.windowResizeHandler);
    }

    componentWillUnmount () {
        window.removeEventListener("resize", null)
    }
    
    windowResizeHandler () {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
    }

    inputChangedHandler (e) {
        this.setState({email: e.target.value})
    }

    buttonClicked () {
        this.verifyEmptyFields()
    }

    verifyEmptyFields() {
        let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.state.email !== '' && this.state.email.match(emailFormat)) {
            this.setState({emailEmpty: false})
            this.props.insertData(this.state.email, [{label: 'email', value: this.state.email}], 0.01);
            this.props.history.push({pathname: '/app', email: this.state.email, language: this.state.language});
        } else {
            this.setState({emptyEmail: true})
        }
    }

    languageToggle () {
        let newLanguage = {...this.state.language};
        if (this.state.language === 'eng') {
            newLanguage = 'ru';
            this.setState({language: newLanguage});
        } else {
            newLanguage = 'eng';
            this.setState({language: newLanguage})
        }
    }

    render () {

        let languageToggle = 
        <div style={{position: 'absolute', top: '20px', right: '24px', zIndex: '100'}}>
            <div style={{display: 'inline-block', lineHeight: '20px', fontSize: '14px', fontWeight: '300', margin: '2px 4px', verticalAlign: 'top', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}>English</div>
            <div style={{backgroundColor: this.state.language !== 'eng' ? 'white' : '#57C2F9', borderRadius: '20px', borderTopRightRadius:'0px', borderBottomRightRadius:'0px', height: '16px', width: '16px', marginTop: '2px', border: '2px solid #57C2F9', display: 'inline-block', transition: '200ms ease-in-out', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}></div>
            <div style={{backgroundColor: this.state.language === 'eng' ? 'white' : '#57C2F9', borderRadius: '20px', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', height: '16px', width: '16px', marginTop: '2px', border: '2px solid #57C2F9', display: 'inline-block', transition: '200ms ease-in-out', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}></div>
            <div style={{display: 'inline-block', lineHeight: '20px', fontSize: '14px', fontWeight: '300', margin: '2px 4px', verticalAlign: 'top', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}>Русский</div>
        </div>

        let socialSharing = 
        <div style={{position:'absolute', top: '24px', zIndex: '100', left: '24px'}}>
            <a href='https://www.facebook.com/sharer.php?u=https%3A%2F%2Fimmigram.io' target='_blank'><img style={{width: '32px', marginRight: '12px', cursor: 'pointer'}} src='https://firebasestorage.googleapis.com/v0/b/max8v-eb3c1.appspot.com/o/f_logo_RGB-Blue_58.png?alt=media&token=11492868-d995-4e72-bd23-9503d80df2dc' /></a>
            <a href='https://twitter.com/intent/tweet?url=https://immigram.io&text=Check%20out%20Immigram%2C%20your%20UK%20immigration%20Consultant&via=immigram&original_referer=https://immigram.io' target='_blank'><img style={{width: '32px', marginRight: '12px', cursor: 'pointer'}} src='https://firebasestorage.googleapis.com/v0/b/max8v-eb3c1.appspot.com/o/Twitter_Social_Icon_Circle_Color.png?alt=media&token=652a7895-2d0a-423e-a789-223925d80282' /></a>
        </div>

        let tcg = <div style={{position:'absolute', bottom: '16px', zIndex: '100', fontWeight: '300', marginTop: '12px', hyphens:'auto', fontSize: '14px', color: '#949292', left: '24px', right: '24px'}}>© 2020 All rights reserved, Immigram.io. <a style={{textDecoration: 'none', color: 'inherit'}} href="https://firebasestorage.googleapis.com/v0/b/immigramio.appspot.com/o/Immigram%20Privacy%20%2B%20GDPR%20%2B%20Cookie%20Polcy.html?alt=media&token=af482ffc-38be-4496-8c95-96434f79f27e" target='_blank'><a>Terms and Conditions, GDPR and Cookie Policy</a></a>.</div>

        return (
            <Auxy>
                {languageToggle}
                <div style={{display: 'flex', height: this.state.windowHeight, flexWrap: 'wrap-reverse', overflow:'hidden'}}>
                    <div style={{backgroundColor: '#EEE7DF', flex: 1, position: 'relative', minWidth: '360px', display: this.state.windowWidth < 720 ? 'none' : 'flex'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '600px', margin: '4%'}}>
                            <img src={logo}
                                alt='logo+planet' 
                                style={{width: ((this.state.windowWidth / 2) - 72), maxWidth: '600px'}}
                            />
                            <h3 style={{fontWeight: '500'}}>{this.state.language === 'eng' ? 'We help talented digital entrepreneurs and IT specialists live a better life in the UK': 'Помогаем талантливым предпринимателям и IT специалистам переехать в Великобританию'}</h3>
                        </div>
                    </div>
                    <div style={{flex: 1, position: 'relative', minWidth: '375px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {socialSharing}
                        {tcg}
                        <div style={{width: ((this.state.windowWidth / 2)), top: this.state.windowWidth < 720 ? '20%' : '30%', minWidth: '320px', marginLeft: '4%'}}>
                        <img src={logo} alt='logo' style={{display: this.state.windowWidth < 720 ? 'block' : 'none', width: '100%'}}/>
                        {/* <h1 style={{display: this.state.windowWidth < 720 ? 'block' : 'none', fontSize: (this.state.windowWidth < 720 ? '3em' : '64px'), textTransform: 'uppercase', letterSpacing: '1.4px', fontWeight: '600', color:'#06317b', color: 'black'}}>Immigram</h1> */}
                            <div style={{}}>
                                <p style={{textTransform: 'uppercase', lineHeight: '24px', textAlign: this.state.windowWidth < 720 ? 'center' : 'left'}}>{this.state.language === 'eng' ? 'Calculate your immigration chances' : 'Давайте рассчитаем ваши шансы на переезд:'}</p>
                                <Input placeholder='email' inputChanged={(e) => this.inputChangedHandler(e)} value={this.state.email} empty={this.state.emptyEmail}/>
                                <Button type='main' isLoading='false' text={this.state.language === 'eng' ? 'Start' : 'Начать'} clicked={() => this.buttonClicked()} windowWidth={this.state.windowWidth}/>
                                <p style={{color: '#949292', fontWeight: '300', width: '80%', maxWidth: '400px', hyphens:'auto', fontSize: '12px'}}>By clicking 'Start' you agree with our <a style={{textDecoration: 'none', color: 'inherit'}} href="https://firebasestorage.googleapis.com/v0/b/immigramio.appspot.com/o/Immigram%20Privacy%20%2B%20GDPR%20%2B%20Cookie%20Polcy.html?alt=media&token=af482ffc-38be-4496-8c95-96434f79f27e" target='_blank'><a>Terms and Conditions, GDPR and Cookie Policy</a></a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxy>
        );
    };
}
    
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};
    
const mapDispatchToProps = dispatch => {
    return {
        insertData: (email, fields, progress) => dispatch(actions.insertData(email, fields, progress))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Landing );