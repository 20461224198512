import * as actionTypes from './actionTypes';
import { fauth, db } from '../../_config/firebase';
import firebase from 'firebase';

export const insertClyStart = () => {
    return {
        type: actionTypes.INSERT_CLY_LINK_START
    };
};

export const insertClySuccess = () => {
    return {
        type: actionTypes.INSERT_CLY_LINK_SUCCESS,
    };
};

export const insertClyError = () => {
    return {
        type: actionTypes.INSERT_CLY_LINK_ERROR,
    };
};

export const getClyStart = () => {
    return {
        type: actionTypes.GET_CLY_START
    };
};

export const getClySuccess = (link) => {
    return {
        type: actionTypes.GET_CLY_SUCCESS,
        link: link
    };
};

export const getClyError = () => {
    return {
        type: actionTypes.GET_CLY_ERROR,
    };
};

export const insertCly = (link) => {
    return dispatch => {
        dispatch(insertClyStart());
        db.collection('calendlyLink').doc('calendlyLink').set({
            link: link
        })
        .then(function(docRef) {
            dispatch(insertClySuccess());
        })
        .catch(function(error) {
            dispatch(insertClyError(error.message));
        })
    };
};

export const getCly = () => {
    return dispatch => {
        dispatch(getClyStart());
        db.collection('calendlyLink').doc('calendlyLink').get().then(function(doc) {
            dispatch(getClySuccess(doc.data()));
        })
        .catch(function(error) {
            dispatch(getClyError(error.message));
        })
    }
}