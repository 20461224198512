import React, { Component } from 'react';
import './Input.css';
import Auxy from '../../hoc/Auxy'

//import UI/UX elements 

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
          };
    };

    render () {
        let inputElement = <div></div>
        switch (this.props.type) {
            case 'username':
                inputElement = 
                <Auxy>
                    <label className='label'>{this.props.label} {this.props.mandatory ? '*' : ''}</label>
                    <input 
                        className='username' 
                        type='text' 
                        onChange={this.props.inputChanged}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                    />
                </Auxy>
                
                break;
            case 'password':
                inputElement = 
                <Auxy>
                    <label className='label'>{this.props.label} {this.props.mandatory ? '*' : ''}</label>
                    <input 
                        className='password' 
                        type='password' 
                        onChange={this.props.inputChanged}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                    />
                </Auxy>
                break;
            case 'text-area':
                inputElement = 
                <Auxy>
                    <label className='label'>{this.props.label} {this.props.mandatory ? '*' : ''}</label>
                    <textarea
                        className='textarea' 
                        type='textarea' 
                        onChange={this.props.inputChanged}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        style={{borderColor: this.props.empty ? '#AF4848' : '#DFE1E6'}}
                    />
                </Auxy>
                break;
            case 'select':
                inputElement = 
                <Auxy>
                    <label className='label'>{this.props.label} {this.props.mandatory ? '*' : ''}</label>
                    <select
                        className='select' 
                        type='select' 
                        onChange={this.props.inputChanged}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        style={{borderColor: this.props.empty ? '#AF4848' : '#DFE1E6'}}
                    >
                        {this.props.options.map(opt => {
                            return <option key={opt.id} style={{display: opt.id === 0 ? 'none' : 'block'}} value={this.props.language === 'eng' ? opt.text : opt.rutext}>{this.props.language === 'eng' ? opt.text : opt.rutext}</option>
                        })}
                    </select>
                </Auxy>
                break;
            default:
                inputElement = 
                <Auxy>
                    <label className='label'>{this.props.label} {this.props.mandatory ? '*' : ''}</label>
                    <input 
                        className='username' 
                        type={this.props.type} 
                        onChange={this.props.inputChanged}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        style={{borderColor: this.props.empty ? '#AF4848' : '#DFE1E6', WebkitAppearance: 'none'}}
                    />
                </Auxy>
        }
        return (
            <Auxy>
                <div>
                    {inputElement}
                </div>
            </Auxy>
        );
    };
}

export default Input;