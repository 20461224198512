import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false,
    email: null,
    fields: [],
    data: []
};

const insertDataStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const insertDataSuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        email: action.email,
        fields: action.fields
     } );
};

const insertDataError = (state, action) => {
    return updateObject( state, {
        error: action,
        loading: false
    });
};

const getDataStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const getDataSuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        data: action.data
     } );
};

const getDataError = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.INSERT_APP_DATA_START: return insertDataStart(state, action);
        case actionTypes.INSERT_APP_DATA_SUCCESS: return insertDataSuccess(state, action);
        case actionTypes.INSERT_APP_DATA_ERROR: return insertDataError(state, action);
        case actionTypes.GET_APP_DATA_START: return getDataStart(state, action);
        case actionTypes.GET_APP_DATA_SUCCESS: return getDataSuccess(state, action);
        case actionTypes.GET_APP_DATA_ERROR: return getDataError(state, action);
        default:
            return state;
    }
};

export default reducer;