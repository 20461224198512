import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false
};

const removeUserStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const removeUserSuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
     } );
};

const removeUserError = (state, action) => {
    return updateObject( state, {
        error: action,
        loading: false
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.REMOVE_USER_START: return removeUserStart(state, action);
        case actionTypes.REMOVE_USER_SUCCESS: return removeUserSuccess(state, action);
        case actionTypes.REMOVE_USER_ERROR: return removeUserError(state, action);
        default:
            return state;
    }
};

export default reducer;