import React, { Component } from 'react';
import './Login.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Auxy from '../../hoc/Auxy'

//import UI/UX elements 

import Input from '../../UI/input/Input';
import Button from '../../UI/button/Button';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginData : [
                {id: 0, type: 'username', value: '', placeholder: 'email@example.com', label: 'Your email'},
                {id: 1, type: 'password', value: '', placeholder: 'qwerty123', label:'Password'},
            ]
        };
    };

    componentDidMount() {
    }

    inputChangedHandler (e, type) {
        let newLoginData = [...this.state.loginData];
        newLoginData.filter(el => el.type === type)[0].value = e.target.value;
        this.setState({loginData: newLoginData})
    }

    render () {
        let error = <div></div>
        if (this.props.error !== 'Not authorized') {
            error = <p className='error'>{this.props.error}</p>
        }
        return (
            <Auxy>
                <div className='login' style={{height: this.props.windowHeight}}>
                    <div className='login-content' style={{padding: '24px'}}>
                        <h3>Login</h3>
                        <p>If you are here, then you should know the credentials :)</p>
                        {this.state.loginData.map(loginEl => {
                            return <Input key={loginEl.id} {...loginEl} inputChanged={(e) => this.inputChangedHandler(e, loginEl.type)}/>
                        })}
                        <Button type='login' text='Take me in' isLoading={this.props.loading} clicked={() => this.props.signIn(this.state.loginData[0].value, this.state.loginData[1].value)}/>
                        {error}
                    </div>
                </div>
            </Auxy>
        );
    };
}
    
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        loading: state.auth.loading,
        error: state.auth.error
    };
};
    
const mapDispatchToProps = dispatch => {
    return {
        signIn: (email, password) => dispatch(actions.auth(email, password))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Login );