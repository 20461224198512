import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false,
    isAuthenticated: false,
    user: null
};

const authStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const authSuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        isAuthenticated: true,
        user: action.user
     } );
};

const authError = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
        isAuthenticated: false
    });
};

const authLogout = (state, action) => {
    return updateObject (state, {
        isAuthenticated: false,
        user: null
    })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_ERROR: return authError(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;