import React, { Component } from 'react';
import './Application.css';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Auxy from '../../hoc/Auxy';
import ReactGA from 'react-ga';

//import UI/UX elements 

import Input from '../../UI/input/Input';
import Button from '../../UI/button/Button';

class Application extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    id: 0, 
                    name: 'First Step: identity', 
                    description: "Tell us a bit about yourself, let's start with your personal details.", 
                    runame: 'Личная информация', 
                    rudescription: "Расскажите о себе", 
                    questions: [
                        {id: 0, label: 'Given Name', value: '', placeholder: 'John', type: 'text', mandatory: true, rulabel:'Имя', ruplaceholder: 'Анастасия'},
                        {id: 1, label: 'Family Name', value: '', placeholder: 'Doe', type: 'text', mandatory: true, rulabel:'Фамилия', ruplaceholder: 'Вернер'},
                        {id: 2, label: 'Country of residence', value: '', placeholder: 'Russia', type: 'text', mandatory: true, rulabel:'Страна проживания', ruplaceholder: 'Россия'},
                        {id: 3, label: 'Age', value: '', placeholder: '26', type: 'text', mandatory: true, rulabel:'Возраст', ruplaceholder: '26'},
                        {id: 4, label: 'Phone', value: '', placeholder: 'your phone number', type: 'number', mandatory: true, rulabel:'Номер телефона', ruplaceholder: 'Ваш номер телефона'},
                        {id: 5, label: 'email', value: this.props.location.email, placeholder: 'your email', type: 'text', mandatory: true, rulabel:'Электронная почта', ruplaceholder: 'email@example.com'}
                    ]
                },
                {
                    id: 1, 
                    name: 'Second Step: intentions', 
                    runame: 'Намерения', 
                    questions: [
                        {id: 0, label: 'What is your intention?', value: '', placeholder: '', type: 'select', options: [{id: 0, text: 'Select an option', rutext: 'Выберите вариант'}, {id: 1, text: 'Work', rutext: 'Работа'}, {id: 2, text:'Business', rutext:'Бизнес'}, {id: 3, text: 'Other', rutext: 'Другое'}], mandatory: true, rulabel:'Цель переезда'},
                        {id: 1, label: 'Why do you want to relocate?', value: '', placeholder: 'Want to move to a better place', type: 'text', mandatory: true, rulabel:'Почему Вы хотите переехать?', ruplaceholder: 'Хочу переехать для того, чтобы расти по карьере'}
                    ]
                },
                {
                    id: 2, 
                    name: 'Third Step: work experience', 
                    runame: 'Опыт работы', 
                    questions: [
                        {id: 0, label: 'Your current occupation', value: '', placeholder: 'Unicorn', type: 'input', mandatory: true, rulabel:'В какой сфере Вы сейчас работаете?', ruplaceholder: 'Стартап'},
                        {id: 1, label: 'Your current position and company name', value: '', placeholder: 'Software Engineer at Startup', type: 'input', mandatory: true, rulabel:'Текущая позиция и название компании', ruplaceholder: 'Ведущий разработчик в Стартапе'},
                        {id: 2, label: 'Company size: people, investments, market presense', value: '', placeholder: '20 ppl, 20M USD funding', type: 'input', mandatory: false, rulabel:'Размер компании: количество сотрудников, инвестиции, рыночная доля', ruplaceholder: '100 человек, 20М USD инвестиций'},
                        {id: 3, label: 'Do you manage people?', value: '', placeholder: '', type:'select', options: [{id: 0, text: 'Select an option', rutext: 'Выберите вариант'}, {id: 1, text: 'Yes', rutext: 'Да'}, {id: 2, text: 'No', rutext: 'Нет'}], mandatory: false, rulabel:'Есть ли у Вас своя команда в подчинении?'},
                        {id: 4, label: 'Do you work with tech: if yes, please elaborate', value: '', placeholder: 'I work on AI initiatives at my company', type:'text', mandatory: false, rulabel:'Работаете ли Вы с IT: если да, расскажите подробнее', ruplaceholder: 'Да, я работаю с искусственным интеллектом'},
                        {id: 5, label: 'Please share your greatest achievements (can be product release, key hirings, opened a side company with a director, virtually anything)', value: '', placeholder: 'Work on the greatest product', type:'text-area', mandatory: true, rulabel:'Расскажите о своих достижениях: может быть релиз продукта, найм ключевых сотрудников, открытие собственного проекта и др.', ruplaceholder: 'Я работаю над продуктом, который абсолютно новый на рынке'},
                        {id: 6, label: 'How many years of relevant experience do you have?', value: '', placeholder: '8', type:'number', mandatory: true, rulabel:'Сколько у Вас лет опыта работы?', ruplaceholder: '8'},
                        {id: 7, label: 'Please share details on previous experience', value: '', placeholder: '8 years of experience at 2 companies', type:'text-area', rulabel:'Расскажите про свой опыт работы детально', ruplaceholder: '8 лет опыта в 2х компания'},
                    ]
                },
                {
                    id: 3, 
                    name: 'Fourth Step: Are you a business owner [skip if not relevant]', 
                    runame: 'Владеете ли Вы бизнесом? [нажмите Далее, если нет]', 
                    questions: [
                        {id: 0, label: 'Do you own the business?', value: '', placeholder: 'SaaS', type: 'select', options: [{id: 0, text: 'Select an option', rutext: 'Выберите вариант'}, {id: 1, text: 'Yes', rutext: 'Да'}, {id: 2, text: 'No', rutext: 'Нет'}], mandatory: false, rulabel:'Владеете ли Вы бизнесом?'},
                        {id: 1, label: 'What your bisiness is about', value: '', placeholder: 'SaaS', type: 'text', mandatory: false, rulabel:'Какой род деятельности у Вашего бизнеса?', ruplaceholder: 'Разработка софта'},
                        {id: 2, label: 'Have you received investments? If yes, what is the current stage and total funding?', value: '', placeholder: 'Yes, $20M USD @ Series A', type: 'text', mandatory: false, rulabel:'Привлекали ли Вы инвестиции? Если да, какая текущая стадия и общий размер инвестиций.', ruplaceholder: 'Да, $20M в раунде А'},
                        {id: 3, label: 'Do you have any traction: share any information that you can share, i.e. number of users, turnover etc.?', value: '', placeholder: '20k SMB clients', type: 'text', mandatory: false, rulabel:'Можете ли Вы предоставить детали по компании: количество пользователей, приблизительный оборот и т.д.?', ruplaceholder: 'Наши клиенты - 20 тысяч маленьких и средних бизнесов'},
                        {id: 4, label: 'Please share your greatest achievements (can be product release, key hirings, opened a side company with a director, virtually anything)', value: '', placeholder: 'Work on the greatest product', type:'text-area', mandatory: false, rulabel:'Расскажите о своих достижениях: может быть релиз продукта, найм ключевых сотрудников, открытие собственного проекта и др.', ruplaceholder: 'Я работаю над продуктом, который абсолютно новый на рынке'},
                    ]
                },
                {
                    id: 4, 
                    name: 'Fifth Step: additional information', 
                    runame: 'Дополнительная информация', 
                    questions: [
                        {id: 0, label: 'What is your relocation budget?', value: '', placeholder: '20k GBP', mandatory: true, rulabel:'Какой у Вас бюджет на переезд?', ruplaceholder: '20 тысяч фунтов'},
                        {id: 1, label: 'Anything you want to ask us?', value: '', placeholder: 'When are going to start working with my case?', mandatory: true, rulabel:'Есть ли у Вас вопросы к нам?', ruplaceholder: 'Когда Вы начнете работать над моим кейсом?'},
                    ]
                },
                {
                    id: 5, 
                    name: 'Sixth Step: schedule a call', 
                    runame: 'Забронируйте звонок с нашим консультантом', 
                    questions: []
                }
            ],
            currentStep: 0,
            applicationSubmitted: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            error: {
                isShown: false,
                text: 'Please, make sure you fill out all mandatory fields',
                emptyFields: [],
                rutext: 'Пожалуйста, заполните обязательные поля'
            },
            language: this.props.location.language !== undefined ? this.props.location.language : 'eng'
        };
        this.windowResizeHandler = this.windowResizeHandler.bind(this)
    };

    componentDidMount () {
        window.addEventListener("resize", this.windowResizeHandler);
    }

    componentWillUnmount () {
        window.removeEventListener("resize", null)
    }
    
    windowResizeHandler () {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
    }

    inputChangedHandler(e, qst) {
        let newSteps = [...this.state.steps];
        let newStep = {...this.state.steps[this.state.currentStep]};
        newStep.questions[qst.id].value = e.target.value;
        this.setState({steps: newSteps})
    }

    buttonClicked() {
        if ((this.state.currentStep + 1) === this.state.steps.length) {
            this.setState({applicationSubmitted: true})
        } else {
        this.emptyFieldsValidation(this.state.currentStep)
        }
    }

    emptyFieldsValidation(stepId) {
        let emptyFields = []
        this.state.steps[stepId].questions.forEach(question => {
            if (question.mandatory && question.value === '') {
                emptyFields.push(question.id)
            }
        })
        if (emptyFields.length === 0) {
            let newCurrentStep = this.state.currentStep + 1;
            let newError = {...this.state.error};
            newError.isShown = false;
            newError.emptyFields = [];
            let progress = (this.state.currentStep + 1)/ (this.state.steps.length - 1);
            if (this.state.currentStep === 5) {
                progress = 1
            };
            this.props.insertData(this.state.steps[0].questions[5].value, this.state.steps[stepId].questions, progress);
            ReactGA.event({
                category: 'Application',
                action: 'Completed Step ' + this.state.currentStep
            });
            this.setState({currentStep: newCurrentStep, error: newError});
        } else {
            let newError = {...this.state.error};
            newError.emptyFields = emptyFields;
            newError.isShown = true;
            this.setState({error: newError})
        }
    }

    languageToggle () {
        let newLanguage = {...this.state.language};
        if (this.state.language === 'eng') {
            newLanguage = 'ru';
            this.setState({language: newLanguage});
        } else {
            newLanguage = 'eng';
            this.setState({language: newLanguage})
        }
    }

    scheduleCallBtnClicked() {
        let newStep = {...this.state.currentStep};
        newStep = this.state.steps.length - 1;
        ReactGA.event({
            category: 'Application',
            action: 'Application skipped'
        });
        this.setState({currentStep: newStep})
    }

    render () {
        let questions = <div></div>
        if (this.state.steps[this.state.currentStep].questions.length > 0) {
            questions = <div>
                {this.state.steps[this.state.currentStep].questions.map(qst => {
                    return <div key={qst.id}>
                        <Input 
                            inputChanged={(e) => this.inputChangedHandler(e, qst)} 
                            empty={this.state.error.emptyFields.includes(qst.id)} 
                            value={qst.value}
                            type={qst.type}
                            mandatory={qst.mandatory}
                            id={qst.id}
                            label={this.state.language === 'eng' ? qst.label : qst.rulabel}
                            placeholder={this.state.language === 'eng' ? qst.placeholder : qst.ruplaceholder}
                            language={this.state.language}
                            options={qst.options}
                        />
                    </div>
                })}
            </div>
        }

        let submissionScreen = <div style={{height: 0}}></div>
        if (this.state.applicationSubmitted) {
            submissionScreen = <div style={{position: 'absolute', top: '0', height: '100%', width:'100%', backgroundColor: '#0065FF', transition: 'height 350ms ease-in-out', padding: '5%'}}>
                <div style={{maxWidth:'500px', minWidth: '280px'}}>
                    <h1 style={{fontSize: '40px', textTransform: 'uppercase', paddingTop: '64px', letterSpacing: '1.4px', fontWeight: '600', color:'#ffffff'}}>Immigram</h1>
                    <p style={{color: '#ffffff'}}>{this.state.language === 'eng' ? 'Alright, we are all set now. Looking forward to spearking with you.' : 'Приятно познакомиться! Спасибо, что заполнили анкету. На звонке обсудим наши дальнейший шаги.'}</p>
                    <p style={{color:'#ffffff'}}>{this.state.language === 'eng' ? 'In the meantime, you can share Immigram with your friends:' : 'Тем временем, можете рассказать о нас своим друзьям:'}</p>
                    <div>
                        <a href='https://www.facebook.com/sharer.php?u=https%3A%2F%2Fimmigram.io' target='_blank'><img style={{width: '40px', marginRight: '24px', cursor: 'pointer'}} src='https://firebasestorage.googleapis.com/v0/b/max8v-eb3c1.appspot.com/o/f_logo_RGB-White_58.png?alt=media&token=23b03925-cbfc-492f-9a91-3a580389891f' /></a>
                        <a href='https://twitter.com/intent/tweet?url=https://immigram.io&text=Check%20out%20Immigram%2C%20your%20UK%20immigration%20Consultant&via=immigram&original_referer=https://immigram.io' target='_blank'><img style={{width: '40px', marginRight: '24px', cursor: 'pointer'}} src='https://firebasestorage.googleapis.com/v0/b/max8v-eb3c1.appspot.com/o/Twitter_Social_Icon_Circle_White.png?alt=media&token=de85a057-e575-4dbc-9a16-a3786f5fa95a' /></a>
                    </div>
                </div>
            </div>
        }

        return (
            <Auxy>
                <div style={{width: (this.state.currentStep+1) / this.state.steps.length*100+'%', height: '4px', backgroundColor: '#57C2F9', position: 'fixed'}}></div>
                <div style={{padding: '4% 2% 84px 2%'}}>
                    {/* language Toggle */}
                    <div>
                    <div style={{display: 'inline-block', lineHeight: '20px', fontSize: '14px', fontWeight: '300', margin: '2px 4px', verticalAlign: 'top', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}>English</div>
                            <div style={{backgroundColor: this.state.language !== 'eng' ? 'white' : '#57C2F9', borderRadius: '20px', borderTopRightRadius:'0px', borderBottomRightRadius:'0px', height: '16px', width: '16px', marginTop: '2px', border: '2px solid #57C2F9', display: 'inline-block', transition: '200ms ease-in-out', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}></div>
                            <div style={{backgroundColor: this.state.language === 'eng' ? 'white' : '#57C2F9', borderRadius: '20px', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', height: '16px', width: '16px', marginTop: '2px', border: '2px solid #57C2F9', display: 'inline-block', transition: '200ms ease-in-out', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}></div>
                            <div style={{display: 'inline-block', lineHeight: '20px', fontSize: '14px', fontWeight: '300', margin: '2px 4px', verticalAlign: 'top', cursor: 'pointer', color: 'black'}} onClick={() => this.languageToggle()}>Русский</div>
                    </div>
                    {this.state.steps.map(step => {
                        return <div key={step.id} style={{display: step.id === this.state.currentStep ? 'block' : 'none'}}>
                            <h1 style={{fontSize: '36px', textTransform: 'uppercase', letterSpacing: '1.4px', fontWeight: '600', color:'#06317b'}}>{this.state.language === 'eng' ? step.name : step.runame}</h1>
                            <p style={{display: this.state.error.isShown ? 'block' : 'none', color: 'red'}}>{this.state.error.text}</p>
                            <div>
                                {questions}
                            </div>
                            <div style={{minWidth: '280px', maxWidth: '1000px', display: step.id === 5 ? 'block' : 'none', height: '600px'}}>
                                <iframe
                                    src="https://calendly.com/immigram-call/30min"
                                    width="100%"
                                    height="100%"
                                    frameborder="0"
                                ></iframe>
                            </div>
                        </div>
                    })}
                    <div style={{width: '96%', maxWidth: '1000px', display: 'flex', position: this.state.windowWidth < 720 ? 'fixed' : 'inherit', marginBottom: this.state.windowWidth < 800 ? '24px' : 'auto', bottom: this.state.windowWidth < 800 ? '0' : 'auto'}}>
                        <Button tyle='main-action' text={this.state.language === 'eng' ? 'Next' : 'Далее'} clicked={() => this.buttonClicked()} />
                    </div>
                </div>
                {submissionScreen}
            </Auxy>
        );
    };
}
    
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};
    
const mapDispatchToProps = dispatch => {
    return {
        insertData: (email, fields, progress) => dispatch(actions.insertData(email, fields, progress)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Application );