import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    loading: false,
    link: null
};

const insertClyStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const insertClySuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        link: action.link
     } );
};

const insertClyError = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const getClyStart = ( state, action ) => {
    return updateObject( state, {
      loading: true,
     } );
};

const getClySuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false,
        link: action.link
     } );
};

const getClyError = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.INSERT_CLY_LINK_START: return insertClyStart(state, action);
        case actionTypes.INSERT_CLY_LINK_SUCCESS: return insertClySuccess(state, action);
        case actionTypes.INSERT_CLY_LINK_ERROR: return insertClyError(state, action);
        case actionTypes.GET_CLY_START: return getClyStart(state, action);
        case actionTypes.GET_CLY_SUCCESS: return getClySuccess(state, action);
        case actionTypes.GET_CLY_ERROR: return getClyError(state, action);
        default:
            return state;
    }
};

export default reducer;